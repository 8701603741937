const translations = {
    header: {
        home: 'Zoho CRM',
    },
    button: {
        documentation: 'Visita la documentación'
    },
    system: {
        title: 'Información del sistema',
        tenant: 'Nombre del contrato',
        name: 'Nombre del Bot',
        identifier: 'Identificador de Bot',
        accessKey: 'Llave de acceso',
    },
    integration: {
        title: 'Información de integración',
        ...JSON.parse('{"client_id":"Client ID","client_secret":"Client Secret","refresh_token":"Refresh token","enable_lead_notifications":"Habilitar notificaciones del Leads","generated":{}}'),
        submit: 'Actualizar',
        generated: {
            title: 'Información generada',
            ...JSON.parse('{}'),
            default: "Nada que ver aquí hasta ahora",
        }
    }
};

export default translations;