const translations = {
    header: {
        home: 'Zoho CRM',
    },
    button: {
        documentation: 'Visite a documentação'
    },
    system: {
        title: 'Informações do sistema',
        tenant: 'Nome do Contrato',
        name: 'Nome do Bot',
        identifier: 'Identificador do Bot',
        accessKey: 'Chave de acesso',
    },
    integration: {
        title: 'Informações de integração',
        ...JSON.parse('{"client_id":"Client ID","client_secret":"Client Secret","refresh_token":"Refresh token","enable_lead_notifications":"Habilitar notificações de Leads","generated":{}}'),
        submit: 'Atualizar',
        generated: {
            title: 'Informações geradas',
            ...JSON.parse('{}'),
            default: "Nada para ver aqui até o momento"
        }
    }
};

export default translations;