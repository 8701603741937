const translations = {
    header: {
        home: 'Zoho CRM',
    },
    button: {
        documentation: 'See the docs'
    },
    system: {
        title: 'System Info',
        tenant: 'Contract Name',
        name: 'Bot Name',
        identifier: 'Bot Identifier',
        accessKey: 'Access Key',
    },
    integration: {
        title: 'Integration information',
        ...JSON.parse('{"client_id":"Client ID","client_secret":"Client Secret","refresh_token":"Refresh token","enable_lead_notifications":"Enable Lead Notifications","generated":{}}'),
        submit: 'Update',
        generated: {
            title: 'Generated information',
            ...JSON.parse('{}'),
            default: 'Nothing to see here for now'
        }

    }
};

export default translations;