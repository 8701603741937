import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

import { Header, BlipData, CreateUser } from './components';

import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';

import { getApplicationDataAsync } from '../../services/application-service';
import { showToast } from '../../services/common-service';
import { BlipService } from '../../services/blip-service';
import { SetupService, SetupServiceError } from '../../services/api-service';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';

const DEFAULT_DATA = JSON.parse('{"client_id":"","client_secret":"","refresh_token":"","enable_lead_notifications":""}');
const BUCKET_ID = "dawntech-zoho-config";

const Setup = () => {
    const [ out, setOut ] = useState(false);
    const [ appInfo, setAppInfo ] = useState(null);
    const [ data, setData ] = useState(DEFAULT_DATA);
    const { t } = useTranslation();

    useEffect(async () => {
        try {
            const application = await getApplicationDataAsync();
            const blipService = new BlipService(application.tenantId, application.authorization);
            blipService.getBucket(BUCKET_ID).then(_data => setData(_data));
            setAppInfo(application);
        } catch(err) {
            showToast({ 
                type: BlipPortalToastTypes.DANGER,
                message: t('error.out')
            });
            setOut(true);
            console.error(err);
        }
    }, []);

    const copyValue = useCallback(async (value) => {
        if(copy(value)) {
            showToast({ 
                type: BlipPortalToastTypes.SUCCESS,
                message: t('success.copied')
            });
        }
    });

    const handleSubmit = useCallback(async (newConfig) => {
        try { 
            const blipService = new BlipService(appInfo.tenantId, appInfo.authorization);
            const setupService = new SetupService(appInfo.tenantId, appInfo.authorization);
            const oldConfig = await blipService.getBucket(BUCKET_ID).catch(err => {
                console.log(err);
                return DEFAULT_DATA;
            });
            await blipService.updateBucket(BUCKET_ID, newConfig);
            await setupService.update(oldConfig, newConfig);

            setData(await blipService.getBucket(BUCKET_ID));

            showToast({ 
                type: BlipPortalToastTypes.SUCCESS,
                message: t('success.updated')
            });
        } catch (err) {
            const error = err instanceof SetupServiceError ? 'validation' : 'update';
            showToast({ 
                type: BlipPortalToastTypes.DANGER,
                message: t(`error.${error}`)
            });
        }
    }, [appInfo]);

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={t('header.home')}
                icon={PAGE_ICON}
                onClick={() => window.open('https://docs.zoho.dawntech.dev')}
            />
            { out && <p>{t('error.out')}</p>}
            { appInfo && 
                <div className="flex mv4">
                    <div className="w-50 mh4">
                        <BlipData appInfo={appInfo}/> 
                    </div>
                    <div className="w-50 mh4">
                        <CreateUser data={data} copy={copyValue} onChangeData={(field) => setData((_data) => ({..._data, ...field}))} onSubmitData={handleSubmit}/>
                    </div>
                </div>
            }
        </div>
    );
};

Setup.propTypes = {};

export default Setup;
