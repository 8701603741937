import React from 'react';
import { useTranslation } from 'react-i18next';

import { Generated } from '../Generated';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';

export const CreateUser = ({data, onChangeData, onSubmitData, copy}) => {
    const { t } = useTranslation();
    return (
        <Card className="min-h-18 pa3">
            <div className="overflow-auto">
                <h4 className="f4 mt0 mb3">
                    {t('integration.title')}
                </h4>
                <div className="mv3">
                    <Input name="client_id" label={t('integration.client_id')} value={data["client_id"]} onChange={(evt) => { onChangeData({ "client_id": evt.target.value}); }} />
                </div>
                <div className="mv3">
                    <Input name="client_secret" label={t('integration.client_secret')} value={data["client_secret"]} onChange={(evt) => { onChangeData({ "client_secret": evt.target.value}); }} />
                </div>
                <div className="mv3">
                    <Input name="refresh_token" label={t('integration.refresh_token')} value={data["refresh_token"]} onChange={(evt) => { onChangeData({ "refresh_token": evt.target.value}); }} />
                </div>
                <div className="mv3">
                    <Input name="enable_lead_notifications" label={t('integration.enable_lead_notifications')} value={data["enable_lead_notifications"]} onChange={(evt) => { onChangeData({ "enable_lead_notifications": evt.target.value}); }} />
                </div>

                <div className="mt3">
                    <Button onClick={() => onSubmitData(data)}>{t('integration.submit')}</Button>
                </div>
                <div className="w-100 mt4">
                    <Generated data={data} copy={copy} />
                </div>
            </div>
        </Card>
    );
};

CreateUser.propTypes = {
};

export default CreateUser;
